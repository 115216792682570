<template>
  <div class="cb-login">
    <img src="@/assets/img/cardBag/bg_cb.png" />
    <div class="lg-box">
      <div class="form-item">
        <span class="lf-icon">
          <img src="@/assets/img/cardBag/icon_phone.png" />
        </span>
        <input type="text" v-model="form.phone" placeholder="请输入手机号码" />
      </div>
      <div class="form-item">
        <span class="lf-icon">
          <img src="@/assets/img/cardBag/icon_pad.png" />
        </span>
        <input type="text" v-model="form.code" placeholder="请输入验证码" />
        <div
          :class="btn.disable ? 'vcode disabled' : 'vcode'"
          @click="getCodeMeds"
        >
          {{ btn.disable ? `${btn.num}s后可重发` : '获取验证码' }}
        </div>
      </div>
      <div class="btn-login" @click="submitMeds">登录</div>
    </div>
  </div>
</template>
<script>
import { reqCardBagLogin, reqCardBagSendCode } from '@/api/card-bag'
export default {
  data() {
    return {
      form: {
        phone: undefined,
        code: undefined,
        plat: 'cgb'
      },
      btn: {
        disable: false,
        timer: null,
        num: 60
      }
    }
  },
  methods: {
    async getCodeMeds() {
      if (!this.btn.disable) {
        const reg = /^1[3456789]\d{9}$/
        if (this.form.phone && reg.test(this.form.phone)) {
          const data = await reqCardBagSendCode({
            phone: this.form.phone,
            plat: 'cgb'
          })
          if (data && data.code === 0) {
            this.$toast.success(data.message)
            this.btn.disable = true
            this.btn.num = 60
            this.btn.timer = setInterval(() => {
              this.btn.num--
              if (this.btn.num <= 0) {
                this.btn.disable = false
                this.btn.timer = null
              }
            }, 1000)
          } else {
            this.btn.disable = false
          }
        } else {
          this.btn.disable = false
          this.$toast.fail('请填写正确的手机号')
        }
      }
    },
    async submitMeds() {
      const reg = /^1[3456789]\d{9}$/
      if (!reg.test(this.form.phone)) {
        this.$toast.fail('请填写正确的手机号')
        return false
      }
      if (this.form.code !== 0 && !this.form.code) {
        this.$toast.fail('请填写验证码')
        return false
      }
      try {
        const { data } = await reqCardBagLogin(this.form)
        if (data) {
          localStorage.setItem('cardbagToken', data.accessToken)
          this.$router.push({ name: 'GardBagOrderList' })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/style/cardBag.less';
</style>
