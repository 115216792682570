import request from '@/utils/request'
import apiConfig from '@/api/apiconfig'
/**
 * 旧平台卡包登录
 */
export function reqCardBagLogin(data) {
  return request({
    url: `${apiConfig.CARDBAG}/cardBag/login`,
    method: 'post',
    data
  })
}
/**
 * 发送验证码
 */
export function reqCardBagSendCode(data) {
  return request({
    url: `${apiConfig.CARDBAG}/cardBag/sendCode`,
    method: 'post',
    data
  })
}
/**
 * 卡包订单列表
 */
export function reqCardBagList(data) {
  return request({
    url: `${apiConfig.CARDBAG}/cardBag/list`,
    method: 'post',
    data
  })
}
/**
 * 卡包退出
 */
export function reqLogout(data) {
  return request({
    url: `${apiConfig.CARDBAG}/cardBag/loginOut`,
    method: 'post',
    data
  })
}
